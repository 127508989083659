import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import { datenschutz } from './datenschutz.module.css';

// markup
const Datenschutz = () => {
  return (
    <Layout pageTitle="Datenschutzerklärung">
      <div className={datenschutz}>
        <p><i>Diese Datenschutzerklärung gilt für alle Seiten der Plattform Apotheken Direkt.</i></p>
        <ol className="direkt-list">
          <li>Information über die Erhebung personenbezogener Daten
            <ol>
              <li>Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website im
                Zusammenhang mit den Angeboten von „Apotheken Direkt“. Personenbezogene Daten sind alle Daten, die auf Sie
                persönlich beziehbar sind, z.B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</li>
              <li>Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist die Engel-Apotheke, Renate Koehler e. K., Rheinstr. 7-9, 64283 Darmstadt, <a href="mailto:info@apotheken-direkt.de">info@apotheken-direkt.de</a>. Unseren Datenschutzbeauftragten Herrn Axel Boos, Rheinstr. 7-9, 64283 Darmstadt, erreichen Sie unter <a
                  href="mailto:datenschutz@apotheken-direkt.de">datenschutz@apotheken-direkt.de</a>.</li>
              <li>Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von Ihnen mitgeteilten Daten (z.B. Ihre
                E-Mail-Adresse sowie Ihr Vor- und Nachname) von uns gespeichert, um Ihre Anfragen zu beantworten. Die in
                diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder
                schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</li>
              <li>Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre
                Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge
                informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.</li>
              <li>Soweit Sie im Rahmen der Nutzung unseres Angebotes eine an der Plattform teilnehmende Apotheke für
                Bestellungen aussuchen, werden die für die Durchführung erforderlichen personenbezogenen Daten an diese
                Apotheke weitergeleitet. Für die weitere Verarbeitung der personenbezogenen Daten durch die einzelne Apotheke
                gelten die jeweils von dieser Apotheke festgelegten Grundsätze der Datenverarbeitung.</li>
            </ol>
          </li>
          <li>Ihre Rechte
            <ol>
              <li>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten (Art. 15 -
                21 DS-GVO):
                <ul>
                  <li>Recht auf Auskunft,</li>
                  <li>Recht auf Berichtigung oder Löschung,</li>
                  <li>Recht auf Einschränkung der Verarbeitung,</li>
                  <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                  <li>Recht auf Datenübertragbarkeit.</li>
                </ul>
              </li>
              <li>Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, sowie wegen Auskünften,
                Berichtigung, Sperrung oder Löschung von Daten wenden Sie sich bitte an unseren Datenschutzbeauftragten, Herrn Axel Boos, Rheinstr. 7-9, 64283 Darmstadt unter <a
                  href="mailto:datenschutz@apotheken-direkt.de">datenschutz@apotheken-direkt.de</a>.
                <br/>
                Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten durch uns zu beschweren.</li>
            </ol>
          </li>
          <li>Erhebung personenbezogener Daten bei Besuch unserer Website
            <ol>
              <li>Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns
                anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren
                Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns
                technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu
                gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
                <ul>
                  <li>IP-Adresse</li>
                  <li>Datum und Uhrzeit der Anfrage</li>
                  <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                  <li>Inhalt der Anforderung (konkrete Seite)</li>
                  <li>Zugriffsstatus/HTTP-Statuscode</li>
                  <li>jeweils übertragene Datenmenge</li>
                  <li>Website, von der die Anforderung kommt</li>
                  <li>Browser</li>
                  <li>Betriebssystem und dessen Oberfläche</li>
                  <li>Sprache und Version der Browsersoftware.</li>
                </ul>
              </li>
              <li>
                Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner
                gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen
                verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier
                durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren
                Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu
                machen.
              </li>
              <li>Einsatz von Cookies:
                <li className="no-number">
                  Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:
                </li>
                <li className="no-number">
                  <ul>
                    <li>Transiente Cookies (dazu 3.3.1)</li>
                    <li>Persistente Cookies (dazu 3.3.2).</li>
                  </ul>
                </li>
                <li className="no-number">
                  <ol>
                    <li>Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen
                      insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich
                      verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner
                      wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn
                      Sie sich ausloggen oder den Browser schließen.</li>
                    <li>Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach
                      Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit
                      löschen.</li>
                  </ol>
                </li>
                <li className="no-number">
                  Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von
                  Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
                  Funktionen dieser Website nutzen können.
                </li>
              </li>
            </ol>
          </li>
          <li>Verarbeitung und Weitergabe Ihrer Bestelldaten
            <ol>
              <li className="no-number">
                Ihre personenbezogenen Bestelldaten werden gem. Art. 6 Abs. 1 S. 1 lit. b DS-GVO, § 22 Abs. 1 Nr. 1 b) BDSG
                verarbeitet und vertraulich behandelt. Diese werden an die von Ihnen ausgewählte Apotheke weitergeleitet. Der
                jeweilige Inhaber der Apotheke ist Ihnen nach § 203 StGB zusätzlich zur Vertraulichkeit verpflichtet. Für die
                weitere Verarbeitung der personenbezogenen Daten durch die einzelne Apotheke gelten die jeweils von dieser
                Apotheke festgelegten Grundsätze der Datenverarbeitung.
                Soweit die ausgewählte Apotheke im Anschluss an ihre Bestellungen mit ihnen kommuniziert, erfolgt diese
                Kommunikation über unsere Plattform. Auch insoweit werden Ihre personenbezogenen Bestelldaten gem. Art. 6 Abs.
                1 S. 1 lit. b DS-GVO, § 22 Abs. 1 Nr. 1 b) verarbeitet und vertraulich behandelt.
                <br/><br/>
                Schutz Ihrer Daten – SSL/TLS
                <br/><br/>
                Für Datenübermittlungen im Zusammenhang mit den zum Bestellvorgang unseres Shops gehörigen Seiten, auf denen
                Sie persönliche Daten eingeben, verwenden wir den Sicherheitsstandard SSL (256bit Secure Socket Layers). Ihre
                Daten werden direkt bei der Übertragung verschlüsselt. Alle datenschutzrelevanten Informationen (wie
                Kreditkartennummer, Bankleitzahl, Kontonummer, Name und Adresse) werden in einer geschützten Datenbank
                abgelegt.
              </li>
            </ol>
          </li>
          <li>Änderung unserer Datenschutzbestimmungen
            <ol>
              <li className="no-number">
                Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
                Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
                bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
              </li>
            </ol>
          </li>
          <li>Einsatz von Google Sign In
            <ol>
              <li className="no-number">
                Statt einer direkten Registrierung auf unserer Website können Sie sich mit Google Sign In registrieren. Google
                Sign-In ist ein Dienst von Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Ireland.
                Wenn Sie sich für die Registrierung mit Google Sign In entscheiden und auf den „Login with Google“- / „Connect
                with Google“-Button klicken, werden Sie automatisch auf die Plattform von Google weitergeleitet. Dort können
                Sie sich mit Ihren Nutzungsdaten anmelden. Dadurch wird Ihr Google-Profil mit unserer Website bzw. unseren
                Diensten verknüpft. Durch diese Verknüpfung erhalten wir Zugriff auf Ihre bei Google hinterlegten Daten. Dies
                sind vor allem:
                <ul>
                  <li>Google-Name</li>
                  <li>Google-Profilbild</li>
                  <li>bei Google hinterlegte E-Mail-Adresse</li>
                </ul>
                Diese Daten werden zur Einrichtung, Bereitstellung und Personalisierung Ihres Accounts genutzt. Wir nutzen von
                diesen Daten ausschließlich solche, die zur Vervollständigung des Nutzerprofils verwendet werden.
                Weitere Informationen finden Sie in den <a
                  href="https://policies.google.com/terms?hl=de-DE">Google-Nutzungsbedingungen</a> und den <a
                  href="https://policies.google.com/privacy?hl=de-DE">Google-Datenschutzbestimmungen</a>.
                <br/>
                Eine Weitergabe von Informationen zu Ihren Bestellungen oder eine Möglichkeit von Google, diese einzusehen,
                besteht nicht.
              </li>
            </ol>
          </li>
          <li>Einsatz von Facebook Sign In
            <ol>
              <li className="no-number">
                Statt einer direkten Registrierung auf unserer Website können Sie sich mit Facebook Sign In registrieren. Facebook
                Sign-In ist ein Dienst von Facebook Ireland Limited („Facebook“), 4 Grand Canal Square, Dublin 2, Irland.
                Wenn Sie sich für die Registrierung mit Facebook Sign In entscheiden und auf den „Login with Facebook“- / „Connect
                with Facebook“-Button klicken, werden Sie automatisch auf die Plattform von Facebook weitergeleitet. Dort können
                Sie sich mit Ihren Nutzungsdaten anmelden. Dadurch wird Ihr Facebook-Profil mit unserer Website bzw. unseren
                Diensten verknüpft. Durch diese Verknüpfung erhalten wir Zugriff auf Ihre bei Facebook hinterlegten Daten. Dies
                sind vor allem:
                <ul>
                  <li>Facebook-Name</li>
                  <li>Facebook-Profilbild</li>
                  <li>bei Facebook hinterlegte E-Mail-Adresse</li>
                </ul>
                Diese Daten werden zur Einrichtung, Bereitstellung und Personalisierung Ihres Accounts genutzt. Wir nutzen von
                diesen Daten ausschließlich solche, die zur Vervollständigung des Nutzerprofils verwendet werden.
                Weitere Informationen finden Sie in den <a
                  href="https://de-de.facebook.com/legal/terms?ref=pf">Facebook-Nutzungsbedingungen</a> und den <a
                  href="https://www.facebook.com/about/privacy/update">Facebook-Datenschutzbestimmungen</a>.
                <br/>
                Eine Weitergabe von Informationen zu Ihren Bestellungen oder eine Möglichkeit von Facebook, diese einzusehen,
                besteht nicht.
              </li>
            </ol>
          </li>
          <li>Einsatz von Apple Sign In
            <ol>
              <li className="no-number">
                Statt einer direkten Registrierung auf unserer Website können Sie sich mit Apple Sign In registrieren. Apple
                Sign-In ist ein Dienst von Apple Inc. („Apple“) One Apple Park Way, Cupertino, California, USA, 95014.
                Wenn Sie sich für die Registrierung mit Apple Sign In entscheiden und auf den „Login with Apple“- / „Connect
                with Apple“-Button klicken, werden Sie automatisch auf die Plattform von Apple weitergeleitet. Dort können
                Sie sich mit Ihren Nutzungsdaten anmelden. Dadurch wird Ihr Apple-Profil mit unserer Website bzw. unseren
                Diensten verknüpft. Durch diese Verknüpfung erhalten wir Zugriff auf Ihre bei Apple hinterlegten Daten. Dies
                sind vor allem:
                <ul>
                  <li>Apple-Name</li>
                  <li>bei Apple hinterlegte E-Mail-Adresse, wenn Sie dem zugestimmt haben, oder eine von Apple generierte E-Mail Adresse, wenn Sie dem nicht zugestimmt haben</li>
                </ul>
                Diese Daten werden zur Einrichtung, Bereitstellung und Personalisierung Ihres Accounts genutzt. Wir nutzen von
                diesen Daten ausschließlich solche, die zur Vervollständigung des Nutzerprofils verwendet werden.
                Weitere Informationen finden Sie in den <a
                  href="https://www.apple.com/de/legal/internet-services/terms/site.html">Apple-Nutzungsbedingungen</a> und den <a
                  href="https://www.apple.com/de/legal/privacy/">Apple-Datenschutzbestimmungen</a>.
                <br/>
                Eine Weitergabe von Informationen zu Ihren Bestellungen oder eine Möglichkeit von Apple, diese einzusehen,
                besteht nicht.
              </li>
            </ol>
          </li>
          <li>Pflichtangaben bei Datenerhebungen außerhalb unserer Webseite
            <ol>
              <li className="no-number">Im Folgenden informieren wir über den Verarbeitungsrahmen bei Datenerhebungen außerhalb
                unseres Internetauftritts, bei denen Sie die Dienstleistungen der Apotheken in Anspruch nehmen.</li>
              <li>Kategorien personenbezogener Daten, die verarbeitet werden:
                <br/>
                Rezeptdaten, Gesundheitsdaten, Adressdaten, Kontaktdaten, Geburtsdatum, Vertragsdaten, Abrechnungsdaten.
              </li>
              <li>Kriterien für die Festlegung der Speicherdauer der personenbezogenen Daten:
                <br/>
                Der Gesetzgeber hat vielfältige Aufbewahrungspflichten und -fristen erlassen. Nach Ablauf dieser Fristen
                werden die entsprechenden Daten routinemäßig gelöscht, wenn sie nicht mehr zur Erfüllung des Auftrags
                erforderlich sind. Sofern Daten hiervon nicht berührt sind, werden sie gelöscht, wenn die zur Erhebung
                genannten Zwecke wegfallen. Einwilligungserklärungen, von denen ein Jahr lang kein Gebrauch gemacht wird (z.B.
                durch den Einsatz der Kundenkarte), werden gelöscht, soweit gesetzliche Aufbewahrungspflichten nicht
                entgegenstehen.
              </li>
              <li>Zwecke, für die die personenbezogenen Daten verarbeitet werden:
                <ul>
                  <li>Arzneimittelversorgung durch eine öffentliche Apotheke</li>
                  <li>Abrechnung gegenüber Krankenkassen</li>
                  <li>Kundenbindung</li>
                </ul>
              </li>
              <li>Rechtsgrundlagen der Datenverarbeitung:
                <ul>
                  <li>Arzneimittelversorgung durch eine öffentliche Apotheke: gesetzliche Erlaubnis, Art. 6 Abs. 1 S. 1 lit.
                    b, 9 Abs. 2 DS-GVO.</li>
                  <li>Abrechnung gegenüber Krankenkassen: gesetzliche Erlaubnis, Art. 6 Abs. 1 S. 1 lit. b, 9 Abs. 2 DS-GVO, §
                    300 SGB V.</li>
                  <li>Kundenbindung: berechtigte Interessen, Art. 6 Abs. 1 S. 1 lit. a DS-GVO, sowie ggf. Einwilligungen, Art.
                    6 Abs. 1 S. 1 lit. a DS-GVO.</li>
                </ul>
              </li>
              <li>Bereitstellung der personenbezogenen Daten für einen Vertragsabschluss erforderlich:
                <br/>
                Ja, was die Versorgung mit verschreibungspflichtigen Arzneimitteln betrifft, die ansonsten nicht erfolgen
                kann.
              </li>
              <li>Kategorien von Empfängern der personenbezogenen Daten:
                <ul>
                  <li>Intern: Mitarbeiter in der Apotheke, die an der Ausführung und Erfüllung der jeweiligen
                    Informationsprozesse beteiligt sind; Botendienst.</li>
                  <li>Extern: externe Stellen, mit denen Auftragsdatenverarbeitungsverträge gem. Art. 28 DS-GVO abgeschlossen
                    werden, sowie Dritte, denen im Rahmen der Vertragsdurchführung die Daten weiterzuleiten sind, wie z.B.
                    Krankenkassen und Apotheken-Abrechnungsstellen; Ärzte, Krankenhäuser, Therapeuten, Betreuungs- und
                    Pflegeeinrichtungen; öffentliche Stellen, die Daten aufgrund gesetzlicher Vorschriften erhalten, wie z.B.
                    Finanzbehörden und Sozialversicherungsträger; EDV-Dienstleister; Steuerberater.</li>
                </ul>
              </li>
              <li>Eine Auslandsübermittlung findet nicht statt und ist nicht vorgesehen.</li>
            </ol>
          </li>
        </ol>
        <p className="app-terms date-of-modification">Stand: 22.03.2022</p>
      </div>
    </Layout>
  )
}

export default Datenschutz
